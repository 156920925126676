import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import { MenuProvider } from "../../context/MenuContext";
// import { IdeasProvider } from "../../context/IdeasContext";
import { isBrowser } from "../../utilities";

import "../../styles/main.scss";

const Layout = ({ children, settings, activeDocMeta }) => {
  // Start of SEO Improvements
  const isNoIndex = isBrowser && window.location.search.includes("?p="); // Checks for "/?p" in url
  const isNoIndexIdeas = isBrowser && window.location.search.includes("?ideas="); // Checks for "/?p" in url
  const isHomepage = isBrowser && window.location.pathname === "/"; // Homepage URL check

  // End of SEO Improvements

  if (!settings) return null;

  const { code_for_head: codeHead, code_for_body: codeBody } = settings.data;

  const [showSticky, setShowSticky] = useState(false);

  if (isBrowser) {
    window.onscroll = () => {
      setShowSticky(window.pageYOffset > 100);
    };
  }

  useEffect(() => {
    if (codeHead.text !== "") {
      document.head.append(document.createRange().createContextualFragment(codeHead.text));
    }
    if (codeBody.text !== "") {
      document.body.append(document.createRange().createContextualFragment(codeBody.text));
    }
  }, [codeBody.text, codeHead.text]);

  return (
    <>
      <Helmet defaultTitle={settings.data.site_short_title}>
        <html lang={settings.lang} />
        {/* This meta is added on case there is /?p= in url */}
        {isNoIndex && <meta name="robots" content="noindex, nofollow" />}
        {/* This meta is added on case there is /?ideas= in url */}
        {isNoIndexIdeas && <meta name="robots" content="noindex, nofollow" />}
        {/* It adds this canonical link only in homepage */}
        {isHomepage && (
          <link rel="canonical" href="https://nasco.gr/" /> // Set canonical link only for homepage
        )}
        <meta name="google-site-verification" content="MRI6mafzLafqbsxOStnU1ktfWe-R4mf7QUHGTgZIcxk" />
        <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/87bdfeb629e6edddbb644ce8/script.js" />
        {/* <!-- Google Tag Manager --> */}
        {/* <script>
          {`(function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({
                  'gtm.start': new Date().getTime(),
                  event:'gtm.js'
                });
                var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),
                dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WZT969M6');`}
        </script> */}
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>
      <MenuProvider>
        {/* <IdeasProvider> */}
        <div className="site">
          {/* <noscript>
            <iframe
              title="gtm"
              src="https://www.googletagmanager.com/ns.html?id=GTM-WZT969M6"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
          </noscript> */}
          <Header settings={settings} activeDocMeta={activeDocMeta} showSticky={showSticky} />
          <main className="site-main">{children}</main>
          <Footer settings={settings} />
        </div>
        {/* </IdeasProvider> */}
      </MenuProvider>
    </>
  );
};

export default Layout;
