import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import MetaSite from "./MetaSite";
import MetaSocial from "./MetaSocial";
import SchemaWebsite from "./SchemaWebsite";
import SchemaOrganization from "./SchemaOrganization";
import SchemaNewsArticle from "./SchemaNewsArticle";
import SchemaItemsList from "./SchemaItemsList";

// SEO Data
// ----------------------------------------------------------------------------
// Pass the entire page object to automatically populate all fields.
//
// Use individual props (ie: "title", "description" etc) on each page to
// override this field from page object.
//
// Fallback values are the siteMetadata defined in config/site-config.js.
// ----------------------------------------------------------------------------

const Seo = ({ page, itemsList, title, shortTitle, description, image, url, siteUrl, lang, logoUrl }) => {
  const config = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          shortTitle
          description
          logoUrl
          siteUrl
          siteLang

          defaultImage
          defaultImageWidth
          defaultImageHeight
          defaultImageAlt
        }
      }
    }
  `);

  const fallback = config.site.siteMetadata;

  // Main page title
  let seoTitle = fallback.title;
  if (title) {
    seoTitle = title;
  } else if (page) {
    const { meta_title: metaTitle, title: pageTitle } = page.data;
    if (metaTitle && metaTitle !== "") {
      seoTitle = metaTitle;
    } else if (pageTitle && pageTitle !== "") {
      seoTitle = pageTitle;
    }
  }

  const titleBrand = new RegExp(`${fallback.shortTitle}$`, "gm").test(seoTitle) ? "" : ` | ${fallback.shortTitle}`;

  seoTitle += titleBrand;

  // Short title
  const seoShortTitle = shortTitle || fallback.shortTitle;

  // Page description
  let seoDescription = fallback.description;
  if (description) {
    seoDescription = description;
  } else if (page) {
    const { description: pageDescription, meta_description: metaDescription } = page.data;
    if (metaDescription) {
      seoDescription = metaDescription;
    } else if (pageDescription && pageDescription.text && pageDescription.text !== "") {
      seoDescription = pageDescription.text;
    }
  }

  // Page URL
  let seoUrl = fallback.siteUrl;
  seoUrl = url || (page && page.url);
  seoUrl = new URL(seoUrl, fallback.siteUrl).href;

  // Site URL
  const seoSiteUrl = siteUrl || fallback.siteUrl;

  // Page language
  let seoLang = fallback.siteLang;
  if (lang) {
    seoLang = lang;
  } else if (page) {
    if (page.lang && page.lang !== "") {
      seoLang = page.lang;
    }
  }

  // Site Logo URL
  const seoLogoUrl = logoUrl || fallback.logoUrl;

  // Page Image
  // --------------------------------------------------------------------------
  // Must be an object which includes the keys "url", "alt", "dimensions".
  // "dimensions" must include the keys "height" and "width".
  let seoImage = {
    url: fallback.defaultImage,
    alt: fallback.defaultImageAlt,
    dimensions: {
      height: fallback.defaultImageHeight,
      width: fallback.defaultImageWidth,
    },
  };
  if (image && image.url && image.url !== "") {
    seoImage = image;
  } else if (page) {
    const { meta_image: metaImage, image: pageImage } = page.data;
    if (metaImage && metaImage.url && metaImage.url !== "") {
      seoImage = metaImage;
    } else if (pageImage && pageImage.url && pageImage.url !== "") {
      seoImage = pageImage;
    }
  }

  return (
    <>
      <MetaSite title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl} siteUrl={seoSiteUrl} lang={seoLang} altLangs={page && page.alternate_languages} />
      <MetaSocial title={seoTitle} shortTitle={seoShortTitle} description={seoDescription} image={seoImage} url={seoUrl} />
      <SchemaWebsite title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl} siteUrl={seoSiteUrl} />
      <SchemaOrganization name={fallback.shortTitle} alternateName={fallback.title} url={fallback.siteUrl} logo={seoLogoUrl} />
      {page && page.type === "post" && <SchemaNewsArticle headline={seoTitle} image={seoImage} datePublished={page.data.publication_date || page.first_publication_date} authorName={fallback.shortTitle} authorAlternateName={fallback.title} authorUrl={fallback.siteUrl} authorLogo={seoLogoUrl} />}
      {itemsList && <SchemaItemsList items={itemsList} siteUrl={seoSiteUrl} />}
    </>
  );
};

export default Seo;
